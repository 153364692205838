/* Global Styles */
:root {
  --primary-color: #5e67a5;
  --dark-color: #424874;
  --light-color: #F4EEFF;
  --danger-color: #99424b;
  --success-color: #368949;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: var(--light-color);
  color: #3f456f;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1250px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead-1 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mt-1 {
  margin: 1rem 0 0 0;
}

.social-parent {
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 0;
}

.shr-btn,
.shr-text {
  padding: 5px;
}

.shr-text {
  padding-top: 12px;
}

/* .twitter {
  right: 7rem;
  bottom: 1rem;
}
.linkedin {
  right: 4rem;
  bottom: 1rem;
} */
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 5px;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  /* background-color: rgba(0, 0, 0, 0.7); */
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: var(--dark-color)
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  background: var(--light-color);
  color: var(--primary-color);
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}

.form .social-input i.fa-facebook {
  color: #3b5998;
}

.form .social-input i.fa-instagram {
  color: #3f729b;
}

.form .social-input i.fa-youtube {
  color: #c4302b;
}

.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  /* opacity: 0.9; */
}

/* .navbar ul {
  display: flex;
} */
.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

/* .navbar a:hover {
  color: var(--primary-color);
} */
.navbar .welcome span {
  margin-right: 0.6rem;
}



/* Landing Page */
.landing {
  background: url('./img/background.jpg') no-repeat center center/cover;
  height: 90vh;
}

.landing-inner {
  color: var(--dark-color);
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 100px;
  text-align: center;

}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp>div,
.profile-edu>div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp>div:last-child,
.profile-edu>div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo>div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github>div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
  position: relative;
}

.post>div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    margin-top: 8rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */
  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }

  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }
}

.or {
  margin-top: 20px;
  font-weight: 200;
}

/* register with social media buttons */
.social-buttons {
  display: flex;
  flex-direction: column;
}

.social-button {
  display: inline-block;
  max-width: 250px;
  margin-bottom: 10px;
  border: 0;
  padding: 0 18px;
  text-align: left;
  width: 100%;
  height: 37px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.84);
  fill: rgba(0, 0, 0, 0.84);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  font: inherit;
  outline: none;
  background: var(--light-color);
  transition: all 0.5s;
  border-radius: 5px;
}

.social-button:hover {
  color: #fff;
  background: var(--primary-color)
}

.svgIcon {
  vertical-align: middle;
  fill: rgba(0, 0, 0, 0.54);
  padding-right: 4px;
  height: 37px;
  display: inline-block;
}

.google {
  margin-top: 20px;
}

/* .github-button-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
} */

.github-icon,
.facebook-icon {
  position: relative;
  left: -71px;
  top: 7px;
}

.sign-in-social-button {
  position: relative;
  top: -22px;
  left: 30px;
}



/* Navbar Submenu */

.nav__menu {
  line-height: 45px;
  /* font-weight: 700; */
  /* text-transform: uppercase; */
}

.nav__menu-item {
  display: inline-block;
  position: relative;
}

.nav__menu-item:hover {
  background-color: var(--primary-color);
}

.nav__menu-item:hover .nav__submenu {
  display: block;
}

.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  min-width: 150px;
  background-color: var(--primary-color);
}

.nav__submenu-item:hover {
  background: rgba(0, 0, 0, 0.1);
}


/* footer */

.footer {
  position: fixed;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
}